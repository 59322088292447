import getConfig from "next/config";

export const magnetoHome: string = getConfig().publicRuntimeConfig.urlHome || "https://www.magneto365.com/home/";
export const magnetoHomeNext: string = getConfig().publicRuntimeConfig.urlHomeNext || "https://www.magneto365.com/es";
export const supportZendesk: string = getConfig().publicRuntimeConfig.supportZendesk;

export const instagram = "https://www.instagram.com/magnetoempleos/";
export const facebook = "https://web.facebook.com/MagnetoEmpleos?_rdc=3&_rdr";
export const linkedin = "https://www.linkedin.com/company/magnetoempleos/mycompany/";
export const youtube = "https://www.youtube.com/c/Magnetoempleos";
export const tiktok = "https://www.tiktok.com/@magnetoempleos";
export const googlePlay = "https://play.google.com/store/apps/details?id=com.magneto365.app";
export const appStore = "https://apps.apple.com/co/app/magneto-empleo-para-todos/id6444812096";
export const appGallery = "https://appgallery.huawei.com/app/C108120693";
export const blog = `${magnetoHomeNext}/blog`;
export const knowUs = `${magnetoHome}/conocenos`;
export const frequentQuestions = `${magnetoHomeNext}/preguntas-frecuentes`;
export const forCompanies = `${magnetoHomeNext}/soluciones`;
export const shareUs = `${magnetoHome}/#comparte-magneto`;
export const jobsByCompany = `${magnetoHome}/empleos-por-empresas`;

export const personalDataPsyconometrics = `${magnetoHomeNext}/politicas/politica-de-tratamiento-de-datos-personales-psyconometrics`;
export const personalDataMagneto = `${magnetoHomeNext}/politicas/politica-de-tratamiento-de-datos-personales-magneto-global-s-a-s`;
export const noticeOfPrivacyPsyconometrics = `${magnetoHomeNext}/politicas/aviso-de-privacidad-psyconometrics`;
export const noticeOfPrivacyMagneto = `${magnetoHomeNext}/politicas/aviso-de-privacidad-magneto-global`;
export const termsAndConditions = `${magnetoHomeNext}/politicas/terminos-condiciones-uso-plataforma-magneto`;
export const SPEResolution = `https://www.magneto365.com/wp-content/uploads/2022/08/RES0333-AUTORIZACION-PSYCONOMETRICS-S.A.S..pdf`;
export const SPEResolution0070 =
  "https://static.magneto365.com/wordpress/2024/05/14104035/RESOLUCION_0070_AUTORIZACION_-_MAGNETOGLOBAL_SAS-1-1.pdf";
export const userConsent = `${magnetoHomeNext}/politicas/consentimiento-de-usuarios`;
export const consentReferrals = `${magnetoHomeNext}/politicas/declaracion-autorizacion-datos-referidos`;
export const authorizationRegulations = `https://www.magneto365.com/wp-content/uploads/2022/09/Reglamento-de-prestacio%CC%81n-de-servicios-06.07.22-VF.pdf`;
export const cookiePolicyPsyconometrics = `${magnetoHomeNext}/politicas/politica-de-cookies-psyconometrics`;
export const cookiePolicyMagneto = `${magnetoHomeNext}/politicas/politica-de-cookies-magneto-global`;

export const siteMap = `${magnetoHome}mapa-del-sitio`;
export const singUpHome = `${magnetoHome}?action=sign-up`;
export const loginUrl = "https://login.magneto365.com/candidates";
export const marbleUrl = "https://www.marbleheadhunter.com";
export const talentaUrl = "https://www.talentacompany.com/";
export const digitalLibrarysUrl = "https://www.bibliotecasdigitales.co/";
export const helpPage = "https://magnetoglobal.zendesk.com/hc/es-419/requests/new";

export const digitalSelection = `${magnetoHomeNext}/soluciones/seleccion-digital`;
export const talentAssessment = `${magnetoHomeNext}/soluciones/evaluacion-integral`;
export const getConsultancy = `${magnetoHomeNext}/soluciones/#form`;
export const postJobOffer = `${magnetoHomeNext}/soluciones/negocios`;
export const pymesPlans = `${magnetoHomeNext}/soluciones/negocios/planes`;

//TODO: review and delete this constants
export const jobsByPosition = `${magnetoHome}/empleos-por-cargo-profesional/`;
export const jobsByIndustry = `${magnetoHome}/empleos-por-sector-laboral/`;
export const jobsByCity = `${magnetoHome}/empleos-por-ciudades/`;
